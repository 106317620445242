import React, { useEffect, useState } from 'react'
import {ThunkableWebviewerExtension} from './ThunkableExtension'
import { Box, Button, Card, CardContent, Modal, Typography } from '@material-ui/core'
import { dateMaker } from './datemaker'



export function WorkList({schedule, users, userInfo}){
    const [order, setOrder] = useState('NONE')
    const [myJobs, setMyJobs] = useState(true)
    const [showNotes, setShowNotes] = useState(false)
    const [notes, setNotes]=useState({})
    const uid = userInfo?userInfo.uid:''
    // Set up one Listener!
    useEffect(()=>{
        ThunkableWebviewerExtension.receiveMessage((event)=>{
            let message
            try {
                message = JSON.parse(event)
                //alert('parsed ok', message)
            } catch (e) {
                message = event
                //console.log('could not parse', message)
            }
            if (message.type ==='RD') {
                console.log("type check", message)
                //alert(message.text)
                if(message.phase) {
                    setOrder(message.id)
                }
            }
        })
    },[])
    
    const button={
        height: '100%',
        width: '50%',
        backgroundColor: 'rgba(1, 76, 126, 1)',
        color: 'white',
    }

    function click (e) {
        setMyJobs(!myJobs)
        //on button click
        //ThunkableWebviewerExtension.postMessage('It Works the first way')
        //console.log('sent?')
    }
    function refresh() {
        const message = JSON.stringify({reqCode: 'refresh'})
        ThunkableWebviewerExtension.postMessage(message)
        console.log('refresh')
    }
    function add() {
        const message = JSON.stringify({reqCode: 'add', uid: userInfo.uid})
        ThunkableWebviewerExtension.postMessage(message)
    }
    function otherClick(e) {
        const stamp = new Date()
        stamp.getMilliseconds()
        console.log('post message')
        window.postMessage(JSON.stringify({type: 'RD', text: 'WebTest', timestamp:stamp.getMilliseconds()}))
    }
    return(
        <>
        <Button variant='contained'size='large' style={{marginTop:'1%'}} onClick={click}>{myJobs?'Show All Jobs': 'Show My Jobs'}</Button>
        {/*<Button onClick={otherClick}>Simulate Received Message</Button>*/}
        <div className='flexDiv'>
        <WorkCardList schedule={schedule} user ={uid} myJobs={myJobs}
         showNotes={showNotes} setShowNotes={setShowNotes}
         notes={notes} setNotes={setNotes}/>
        </div>
        <Modal open={showNotes} onClose={()=>{setShowNotes(false)}}>
            <>
            <NotesModal notes={notes} setShowNotes={setShowNotes}/>
            </>
        </Modal>
        <div className='actionHolder' >
            <Button style={button} variant='contained' onClick={add}>Add Monitor</Button>
            <Button style={button} variant='contained' onClick={refresh}>Refresh</Button>
        </div>
        </>
    )
}

function WorkCardList({schedule, users, user, myJobs, setNotes, setShowNotes }) {
    let cards = schedule.map((job)=> {
        if(myJobs){
            if((user==job.dropid && !job.dropped)||(!job.picked && user==job.pickid)||(!job.radon&&user==job.inspectorid)) {
                return <WorkCard key={job.id} job={job} 
                    setShowNotes={setShowNotes}
                    setNotes={setNotes} user={user}/>
            }
        } else {
            return <WorkCard key={job.id} job={job}
                setShowNotes={setShowNotes}
                setNotes={setNotes} user={user}/>
        }
    })
    
    return(
        <>
        {cards}
        </>
    )
}

function WorkCard ({job, setNotes, setShowNotes, user}){
    function onClick (e){
        e.preventDefault()
        const time = new Date()
        setNotes({
            id: job.id,
            date: dateMaker(workDate),
            notes: job.notes,
            address: job.address,
            phase: workPhase,
            user: user,
        })
        setShowNotes(true)
        const message = JSON.stringify({id:job.id, type:'RD', timestamp:time.valueOf()})
        ThunkableWebviewerExtension.postMessage(message)
    }
    //console.log('worklist', job.id, job.dropped.toDate())
    let workPhase = job.radon?job.dropped?'Pickup':'Dropoff':'Inspection'
    let workDate = job.radon?
        job.dropped? new Date(job.pickupdate.seconds*1000):
        new Date(job.dropoffdate.seconds*1000):
        new Date(job.inspdate.seconds*1000)
    let workName = ''
    switch(workPhase){
        case 'Pickup':
            workName = job.pickupName
            break
        case 'Dropoff':
            workName = job.dropoffName
            break
        case 'Inspection':
            workName = job.inspectorName
            break
        default:
            workName='No Name Stored'
    }
    const time = new Date()
    workDate.setMinutes(workDate.getMinutes()+workDate.getTimezoneOffset())
    
    return(
        <Card className='workCard' variant='outlined' onClick={onClick}>
            <CardContent className='workContent'>
                <Typography className='workAddress'>{job.address}</Typography>
                <Typography className='workDate'>{dateMaker(workDate)}</Typography>
                <Typography className='workPhase'>{workPhase}</Typography>
                <Typography className='workName'>{workName}</Typography>
            </CardContent>
        </Card>
    )
}

function NotesModal({notes, setShowNotes}) {
    
    function triggerRead(e) {
        //console.log('modal notes', notes)
        const message = JSON.stringify(
            {id: notes.id, 
            phase: notes.phase,
            uid: notes.user,
            address: notes.address,
            reqCode: 'scan'})
        ThunkableWebviewerExtension.postMessage(message)
        setShowNotes(false)
    }
    
    let sx = {
        backgroundColor:'white',
        width: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '5%',

    }
    return(
        <Box sx={sx}>
            <Typography style={{fontWeight: 'bold'}}>{notes.address}</Typography>
            <Typography>{notes.date}</Typography>
            <Typography>{notes.notes?notes.notes:'No Notes Saved'}</Typography>
            <Button onClick={()=>setShowNotes(false)}>Back</Button>
            <Button onClick={triggerRead}>Scan</Button>
        </Box>
    )
}

