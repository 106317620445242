import { createTheme} from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
          main:'#014c7e',
        },
      },
  });

  export default theme