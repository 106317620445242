import React from 'react'
import { dateMaker } from './datemaker'
import { TextField, Typography } from '@material-ui/core'


export function ScheduleDate({input, company, drop, onChange}){
    let done = false
    const now = new Date()

    const [datetime, setTime] = React.useState(now.toISOString().slice(0,-8))
    onChange(datetime)
    if (input){
        done = input.done
        setTime(input.date.toISOString().slice(0,-8))
    }
   
    //console.log('picker', params.value, params.value.toISOString().slice(0,-8))
    //const [datetime, setTime] = React.useState(input.date.toISOString().slice(0,-8))
    const handleChange = (event)=>{
      //console.log('picker change', event.target.value, new Date(event.target.value))
      setTime(event.target.value)
      onChange(event.target.value)

      /*let updateObj={}
      if(drop){
        updateObj={
          dropoffdate: new Date(event.target.value)
        }
      } else{
        updateObj={
          pickupdate: new Date(event.target.value)
        }
      }
      console.log('update', company, input.id, updateObj)
      //db.collection('companies').doc(company).collection('inspections').doc(input.id).update(updateObj)
      */
    }
    if(done){
      console.log('plaindate', input.date)
      return <Typography variant='body2'>{dateMaker(input.date)}</Typography>
    }else{
      return(
        <TextField
          type='datetime-local'
          value= {datetime}
          onChange={handleChange}
          inputProps={{
            style:{fontSize:'14px'}
          }}
        />
      )
      }
  }