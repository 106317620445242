import React from 'react'
import { Select, MenuItem, Typography } from '@material-ui/core'

export function UserSelect({input, users, company, drop, style, onChange}){
    //console.log('user select order No', params.id, params.value.userName)
    let dropped
    const [user, setUser]= React.useState('') 

    //if there is initial state
    if(input){
        dropped = input.done
        setUser(input.user)
    }
    //set default style
    let applyStyle = style?style:{width:'100%', fontSize:'14px'}
    
    const handleChange = (event)=>{
      setUser(event.target.value)
      onChange(event.target.value)
      //console.log('handleChange', event.target)
      
      /*const user =users.find((doc)=> doc.id===event.target.value)
      let update={}
      if(drop){
        update = {
          dropid: event.target.value,
          dropoffName: user.name +' '+user.lname
        }
      }else{
        update = {
          pickid: event.target.value,
          pickupName: user.name +' '+user.lname
        }
      }
  
      console.log('update:', company, input.oid, update)
      
      db.collection('companies').doc(company).collection('inspections')
      .doc(input.oid).update(update)
      */
      
    }
    //console.log('dropped', dropped )
    let select = users && users.map((user)=> <MenuItem key={user.id} value={user.id}>{user.name + ' ' + user.lname}</MenuItem>)
    //console.log('select', select)
    if(dropped){
      return <Typography variant='body2'>{input.userName}</Typography>
    }else{
      return (
      <Select value={user} onChange={handleChange} variant='outlined' style={applyStyle}>
        {select}
      </Select>
      )
    }
  }