import React from 'react'
import { createTheme, ThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';
import { Modal, TextField, Typography, Button } from '@material-ui/core';
import { StatePicker } from './StatePicker';
import { UserSelect } from './UserSelect';
import { ScheduleDate } from './ScheduleDate';
import firebase from 'firebase'

const useStyles = makeStyles((theme)=>({
    scheduleWindow:{
        position: 'absolute',
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        padding: '16px',
        height: 'auto',
        width: '500px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 1,
        borderStyle: 'solid',
        borderRadius: 10,
    },
    scheduleMobile:{
        position: 'absolute',
        color: theme.palette.primary.main,
        backgroundColor: 'white',
        padding: '16px',
        boxSizing:'border-box',
        height: 'auto',
        width: '90%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 1,
        borderStyle: 'solid',
        borderRadius: 10,
    },
    scheduleAddress:{
        boxSizing: 'border-box',
        width:'100%',
        padding:'5px 5px',
    },
    scheduleCity:{
        boxSizing: 'border-box',
        width:'75%',
        height: 'auto',
        flexGrow:1
    },
    scheduleState:{
        boxSizing: 'border-box',
        margin: '1%',
        height: '56px',
        padding:'0px 10px',
        fontSize: '14px'
    },
    
}))

let sectionStyle = {
  display:'flex', 
  height: '60px',
  alignItems: 'center', 
  margin:'5px 0px',
  padding:'0% 1%'
}
export function SchedulePopup({open, company, big, showHide, users, db}){
    const classes = useStyles()
    //set defaults?
    
    let newOrder = {}
    function onSave(){
      //Validate Info
      console.log(company.id)
      //default new order stuff
      const orderDefault={
        radon: true,
        dropped:false,
        picked: false,
        insp:'',
        inspDate:'',
        inspectorName:'',
        address2:''
      }
      //assign a number
      let oid= 'RD'
      let counter = company.manualCounter
      if(counter === undefined){
        counter=0
      }
      counter++
      if(counter<1000){
          counter = counter.toString().padStart(4,0)
      }    
      oid += counter
      newOrder={...newOrder, ...orderDefault}
      //save to db inspections
      db.collection('companies').doc(company.id).collection('inspections').doc(''+oid)
      .set(newOrder)
      .then(()=>{
        return db.collection('companies').doc(company.id).update({
          manualCounter : parseInt(counter)
        })
      })
      console.log('save!', newOrder)
      //.then - on success
      showHide(false)
    }
  
    function onCancel(){
      //reset all fields, flip open to false
      showHide(false)
    }
    function addressChange(event){
      newOrder.address = event.target.value
    }
    function cityChange(event){
      newOrder.city = event.target.value
    }
    function stateChange(state){
      newOrder.state = state
    }
    function updateDropUser(uid){
      newOrder.dropid=uid
      let index = users.findIndex((userDoc)=> userDoc.id===uid)
      newOrder.dropoffName= users[index].name + ' '+ users[index].lname
      console.log('changed to', newOrder)
    }
    function updateDropSchedule(date){
      newOrder.dropoffdate=new Date(date)
      console.log('changed to', newOrder)
    }
    function updatePickUser(uid){
      newOrder.pickid=uid
      let index = users.findIndex((userDoc)=> userDoc.id===uid)
      newOrder.pickupName= users[index].name + ' '+ users[index].lname
      console.log('changed to', newOrder)
    }
    function updatePickSchedule(date){
      newOrder.pickupdate=new Date(date)
      console.log('changed to', newOrder)
    }

  
    return(
      <Modal open={open}>
        <div className={big?classes.scheduleWindow:classes.scheduleMobile} style={{display:'block'}}>
        <Typography align='center' variant='h6'>Add New Job</Typography>
        <TextField
            id="address"
            label="Address"
            type="address"
            autoComplete="address"
            variant="outlined"
            className={classes.scheduleAddress}
            onChange={addressChange}
          />
          <section style={sectionStyle}>
          <TextField
            id="city"
            label="City"
            type="city"
            autoComplete="city"
            variant="outlined"
            className={classes.scheduleCity}
            onChange={cityChange}
          />
          <StatePicker 
            defaultState='IL' 
            className={classes.scheduleState}
            onChange={stateChange}
            />
          </section>
          <section style={sectionStyle}>
            <span style={{textAlign:'left', flexGrow:'1',}}>Drop Off User</span>
            <UserSelect style={{margin: '1%', flexGrow:'1', fontSize:'14px'}} 
              users={users} company={company} drop={true} onChange={updateDropUser} />
          </section>
          <section style={sectionStyle}>
            <span style={{textAlign:'left', flexGrow:'1',}}>Drop Off Time</span>
            <ScheduleDate company={company} drop={true} onChange={updateDropSchedule}/>
          </section>
          <section style={sectionStyle}>
            <span style={{textAlign:'left', flexGrow:'1',}}>Pick Up User</span>
            <UserSelect style={{margin: '1%', flexGrow:'1', fontSize:'14px'}}
               users={users} company={company} drop={false} onChange={updatePickUser} />
          </section>
          <section style={sectionStyle}>
            <span style={{textAlign:'left', flexGrow:'1',}}>Pick Up Time</span>
            <ScheduleDate  company={company} drop={false} onChange={updatePickSchedule}/>
          </section>
          <Button onClick={()=>onSave()}>Save</Button>
          <Button onClick={()=>onCancel()}>Cancel</Button>
        </div>
        
      </Modal>
    )
  }