import { Button, Switch, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import {ThunkableWebviewerExtension} from './ThunkableExtension'
import {Grid} from '@material-ui/core'

export function SubmitDetails(){
    let location = useLocation()
    //console.log('location:', location)
    let params = new URLSearchParams(location.search)
    const jobID = decodeURIComponent(params.get('id'))
    const monNum = decodeURIComponent(params.get('monnum'))
    const phase = decodeURIComponent(params.get('phase'))
    const leftStyle = {
        width:'50%',
        textAlign:'left'
    }
    const rightStyle = {
        width:'50%',
        textAlign:'right'
    }
    const buttonDiv={
        position:'fixed',
        left: 0,
        bottom:'0%',
        width:'100%',
        height:'10%',
    }

    const button={
        height: '100%',
        width: '50%',
        backgroundColor: 'rgba(1, 76, 126, 1)',
        color: 'white',
    }

    return(
        <div style={{overflow:'hidden'}}>
        <Typography>Review and Save</Typography>
        <div style={{display:'flex',flexWrap:'wrap'}}>
            <Typography style={leftStyle}>Monitor Number:</Typography>
            <Typography style={leftStyle}>{monNum}</Typography>
            <Typography style={leftStyle}>Address:</Typography>
            <Typography style={leftStyle}>Test Address</Typography>
            
            <Grid style={{width:'100%', flexGrow:'1'}}component="label" container alignItems="center" spacing={1}>
                <Grid item style={{textAlign:'right', flexGrow:'2'}}>Pickup</Grid>
                <Grid item style={{flexGrow:'1'}}><Switch/></Grid>
                <Grid item style={{textAlign:'left', flexGrow:'2'}}>Dropoff</Grid>
            </Grid>
        </div>
        <div className='actionHolder' style={buttonDiv}>
            <Button style={button} variant='contained' >Save</Button>
            <Button style={button} variant='contained'>Cancel</Button>
        </div>
        </div>
    )
}