/*global React*/
/*global MaterialUI*/
/*eslint no-undef: "error"*/
import './App.css';
//import db from './firebase.config'
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { AppBar, IconButton, List, Toolbar, 
  Typography, ListItem, ListItemText, Drawer, Table, 
  TableHead, TableCell, TableBody, TableRow, CardContent, 
  Card, Grid, Divider, useMediaQuery, Modal,
  TextField, MenuItem, Select, Fab} from '@material-ui/core';

import { DataGrid, useGridParamsApi } from '@material-ui/data-grid';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add'
import { createTheme, ThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';

import deepOrange from '@material-ui/core/colors/deepOrange';
import blue from '@material-ui/core/colors/blue';
//remove later
import firebase from 'firebase'
import reactDom from 'react-dom';
import { StatePicker } from './components/StatePicker';
import { SchedulePopup } from './components/SchedulePopup';
import { WorkList } from './components/WorkList';

// import react-router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";
import { SubmitDetails } from './components/SubmitDetails';
import { ThunkableWebviewerExtension } from './components/ThunkableExtension';

var firebaseConfig = {
    apiKey: "AIzaSyBkzeIk579HIQoNGJXTppELV8Gix3vVnAg",
    authDomain: "radontracker-be733.firebaseapp.com",
    databaseURL: "https://radontracker-be733.firebaseio.com",
    projectId: "radontracker-be733",
    storageBucket: "radontracker-be733.appspot.com",
    messagingSenderId: "656584830451",
    appId: "1:656584830451:web:242cb9c34c0ecc11d77686",
    measurementId: "G-QC2NXM5WRE"
    };

const firebaseApp = firebase.initializeApp(firebaseConfig);
var db = firebase.firestore()
var functions = firebase.functions();
let companyID = '601416'
//define getUsers to poll backend for all company users
const getUsers = firebase.functions().httpsCallable("getUsers");



const useStyles = makeStyles ((theme)=>({
  title:{
    flexGrow:1,
  },
  dashDivider:{
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 100, 
    width:'100%'
  },
  appBar:{
    alignItems: 'left'
  },
  list:{
    width:250,
  },
  fullList:{
    width:'auto'
  },
  dashCards:{
    width:'250px',
    height: '250px',
    margin: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 1,
    borderStyle: 'solid',
    borderRadius: 10,

  },
  cardGrid:{
    direction: 'row',
    width: '100%',
    justifyContent: 'center'
  },
  welcomeTitle:{
    align: 'left',
    variant: 'h3'
  },
  welcomeName:{
    align: 'left',
    variant: 'h4'
  },
  buttonStyle:{
    margin: theme.spacing(1),
  },
  loginStyle:{
    position: 'absolute',
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    margin:'auto',
    height: 'auto',
    width: 416,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 1,
    borderStyle: 'solid',
    borderRadius: 10,
  },
  loginMobile:{
    position: 'absolute',
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    margin:'auto',
    height: 'auto',
    width: '90%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 1,
    borderStyle: 'solid',
    borderRadius: 10,
  }
  ,
  loginText:{
    flexGrow:1,
    boxSizing: 'border-box',
    width:'100%',
    height: 'auto',
    padding: theme.spacing(1)
  },
  loginMobileText:{
    flexGrow:1,
    boxSizing: 'border-box',
    width:'100%',
    height: 'auto',
    padding: theme.spacing(1)
  },
  scheduleCard:{
    width:'500px',
    height: 'auto',
    margin: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: 1,
    borderStyle: 'solid',
    borderRadius: 10,

  },
  addButton:{
    position:'fixed',
    bottom:'10px',
    right:'10px'
  }
}))


function App() {
  const [drawerState, setDrawer] = React.useState(false)
  const [displayState, setDisplay] = React.useState('Dashboard')
  const [companyDoc, updateCompanyInfo] = React.useState({})
  const [userDoc, updateUserDoc]=React.useState([])
  const [monitors, updateMonitors]=React.useState([])
  const [users, updateUsers]=React.useState([])
  const [schedule, updateSchedule]=React.useState([])
  const [loginModal, setLoginModal] = React.useState(false)
  const [scheduleModal, setSchedule] = React.useState(false)
  const [dashInfo, dashUpdate] = React.useState(
    {inventory:{
        total:'-',
        deployed:'-'
      },
      jobs:{
        upcoming:'-',
        radon:'-',
      },
      schedule:{
        dueToday:'-',
        dueTomorrow:'-',
        missedPickup:'-'
      }})
  const drawerUpdate = (opened)=>{
    setDrawer(opened)
  }

  const updateDash = (update) =>{
    dashUpdate(update)
  }

  const updateDisplay = (display)=>{
    setDisplay(display)
  }

  const closeModal = ()=>{
    setLoginModal(false)
  }
  let classes = useStyles()
  const [array, updateArray]= React.useState([[11,12,13,14],[21,22,23,24],[31,32,33,34]])
  //setup onetime stuff
  useEffect(()=>{
    //setup for unsubscribes
    let userUpdate
    let dashboardUpdate
    let monitorUpdate
    let scheduleUpdate
    let pickScheduleUpdate

    // set loader in Thunkable
    let firstRun=true;
    //login/check auth state
    firebase.auth().onAuthStateChanged(function(user) {
      if(user){
        console.log('logged in')
        setLoginModal(false)
        //if logged in, get uid  then company info
        let userID=firebase.auth().currentUser.uid
        //then get user info
        userUpdate = db.collection("users").doc(userID)
          .get()
          .then((doc)=>{
            let updateObj = doc.data()
            updateObj.uid=userID
            doc.exists && updateUserDoc(updateObj)
            //doc.exists && updateUserDoc({...userDoc, uid:userID})
            return updateObj.company
          })
          //then get company, user, schedule info (all of it)
          .then((companyID)=>{
            //company Info
            dashboardUpdate = db.collection('companies').doc(companyID)
            .onSnapshot((doc)=>{
              doc.exists && updateCompanyInfo(()=>{
                let docObj ={}
                docObj={...doc.data()}
                docObj.id=doc.id
                return docObj
              })
            })
            //monitor info
            monitorUpdate = db.collection('companies').doc(companyID).collection('detectors')
            .orderBy('deployed')
            .onSnapshot((monitorResults)=>{
              monitorUpdater(monitorResults.docChanges(), updateMonitors)
            })

            //user info - snapshot listener
            userUpdate = db.collection('users').where('company','==', companyID)
            .orderBy('inventory','desc')
            .onSnapshot((userDocs)=>{
              console.log('user response - snapshot ready')

              userUpdater(userDocs.docChanges(), updateUsers)
            })

            //schedule info - snapshot listener
            scheduleUpdate = db.collection('companies').doc(companyID).collection('inspections')
            .where('radon','==',true)
            .where('picked','==', false)
            .onSnapshot((scheduleDocs)=>{
              scheduleUpdater(scheduleDocs.docChanges(), updateSchedule, companyID)
              if(firstRun) {
                console.log('ready!')
                const ready = JSON.stringify({reqCode: 'ready'})
                ThunkableWebviewerExtension.postMessage(ready);
                firstRun=false
              }
            })



          })
          .catch(()=>{
            console.log('user info error')
          })
      }else{
        //show login popup
        console.log('not logged in')
        const ready = JSON.stringify({reqCode: 'ready'})
        ThunkableWebviewerExtension.postMessage(ready);
        setLoginModal(true)
        //firebase.auth().signInWithEmailAndPassword('testuser@test.com','testpassword')
      }
    }) 
    
    
    

    return function cleanup(){
      dashboardUpdate()
      monitorUpdate()
      userUpdate()
    }

  },[])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  matches && (displayState==='Dashboard')&& setDisplay('Monitors')
  return (
    
      <div className="App">
        <Router>
        <AppBar position='sticky' >
          <Toolbar>
            <IconButton color='inherit' onClick={()=>setDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <div align='center' className={classes.title}>
            <Typography variant='h5' align='center' className={classes.title}>
              RadonDash
            </Typography>
            <Typography variant='h6' align='center'>
            {matches?'Dashboard':displayState}
          </Typography>
          </div>
          <IconButton disabled color='inherit' onClick={()=>{}}>
            <SettingsIcon/>
          </IconButton>
          </Toolbar>
        </AppBar>
        
          
        
        
        {/*<Welcome companyInfo = {companyDoc} userInfo={userDoc}/>*/}
        {/*
        {(displayState ==='Dashboard'|| matches) && 
          <Dashboard dashInfo={companyDoc} userInfo={userDoc} users={users} updateInfo = {updateDash}/>}
        {(displayState!=='Dashboard')&&
        (matches)&&(<div className={classes.dashDivider}>
        <Divider/>
        
        <Typography variant='h6' align='center'>
          {(displayState==='Dashboard')?'Monitors':displayState}
          </Typography>
          {(displayState==='Monitors') &&
          <div>
            <Button color='secondary' variant='contained' className={classes.buttonStyle}>Add Monitor</Button>
            <Button color='secondary' variant='contained' className={classes.buttonStyle}>Print QR Codes</Button>
          </div>}
        </div>)}
        {(displayState ==='Monitors')&& <MonGrid monitors={monitors} users={users} schedule={schedule}/>}
        {(displayState ==='Inventories')&& <UserGrid userDocs={users}/>}
        {(displayState ==='Schedule') && ((matches)?
          <ScheduleGrid schedule={schedule} users={users} company={userDoc.company}/>
          :<MobileSchedule schedule={schedule} users={users} company={userDoc.company}/>)}
        {(displayState==='Worklist') && 
        <WorkList schedule={schedule} userInfo={userDoc}/>}
        {/*<TableBuilder monitors={monitors} />*/}
        
        
        <MenuBuilder drawerState = {drawerState} updateState = {drawerUpdate} updateDisplay = {updateDisplay}/>
        <LoginPopup open={loginModal} big={matches}/>
        <SchedulePopup open={scheduleModal} company={companyDoc} big={matches} showHide={setSchedule} users={users} db={db}/>
        {(displayState === 'Schedule') && (<Fab color='primary' className={classes.addButton} onClick={()=>{setSchedule(true)}}>
          <AddIcon />  
        </Fab>)} 
        <Switch>
            
            <Route path='/manage/inventories'>
              <UserGrid userDocs={users}/>
            </Route>
            <Route path='/manage/monitors'>
              <MonGrid monitors={monitors} users={users} schedule={schedule}/>
            </Route>
            <Route path='/manage/schedule'>
              {((matches)?
                <ScheduleGrid schedule={schedule} users={users} company={userDoc.company}/>
                :<MobileSchedule schedule={schedule} users={users} company={userDoc.company}/>)}
            </Route>
            <Route path='/worklist'>
              <WorkList schedule={schedule} userInfo={userDoc}/>
            </Route>'
            <Route path='/manage'>
              <Dashboard dashInfo={companyDoc} userInfo={userDoc} users={users} updateInfo = {updateDash}/>
            </Route>
            <Route path='/submitdetails'>
              <SubmitDetails/>
            </Route>
            <Route path='/'>
              <Dashboard dashInfo={companyDoc} userInfo={userDoc} users={users} updateInfo = {updateDash}/>
            </Route>
          </Switch> 
        </Router>
        </div>
    
  );

  }



function MobileSchedule({schedule, users, company}){
  let classes = useStyles()
  let jobs = schedule && schedule.map((orderDetail)=>
    <OrderCard key= {orderDetail.id} orderData={orderDetail} users={users} company={company}/>)
  return(
  <Grid container className={classes.cardGrid}>
    {jobs}
  </Grid>
  )
  
}

function OrderCard({orderData, users, company}){
  //Make user input
  let dropUser = {
    user: orderData.dropid,
    userName: orderData.dropoffName,
    done:  orderData.dropped,
    oid: orderData.id,
  }
  let dropSchedule = {
    date: orderData.dropoffdate && orderData.dropoffdate.toDate(),
    oid: orderData.id,
    done: orderData.dropped,
  }
  let pickUser={
    user: orderData.pickid,
    userName: orderData.pickupName,
    done:  orderData.picked,
    oid: orderData.id,
  }

  let pickSchedule={
    date: orderData.pickupdate && orderData.pickupdate.toDate(),
    oid: orderData.id,
    done: orderData.picked,
  }
  const classes=useStyles()
  let sectionStyle = {
    display:'flex', 
    height: '60px',
    alignItems: 'center', 
    margin:'5px 0px'
  }

  //control state/updates from here so components below can be reused
  function updateDropUser(uid){

  }
  function updateDropSchedule(date){

  }
  function updatePickUser(uid){

  }
  function updatePickSchedule(date){

  }
  return(
    <Card className={classes.scheduleCard} >
    <CardContent>
      <Typography variant='h6'>
        {orderData.address}
      </Typography>
      <section style={sectionStyle}>
        <span>Inspector</span><span style={{flexGrow:'1'}}>{orderData.inspectorName}</span>
        </section>
        <section style={{display:'flex'}}>
        <span>Inspection Time</span><span style={{flexGrow:'1', textAlign:'right'}}>{orderData.inspdate?dateMaker(orderData.inspdate.toDate()):''}</span>
        </section>
      <section style={sectionStyle}>
        <span style={{textAlign:'left', flexGrow:'1',}}>Drop Off User</span>
        <UserSelect onChange={updateDropUser}style={{margin: '1%', flexGrow:'1', fontSize:'14px'}} input={dropUser}users={users} company={company} drop={true} />
      </section>
      <section style={sectionStyle}>
        <span style={{textAlign:'left', flexGrow:'1',}}>Drop Off Time</span>
        <ScheduleDate  input = {dropSchedule}company={company} drop={true}/>
      </section>
      <section style={sectionStyle}>
        <span style={{textAlign:'left', flexGrow:'1',}}>Pick Up User</span>
        <UserSelect style={{margin: '1%', flexGrow:'1', fontSize:'14px'}} input={pickUser}users={users} company={company} drop={false} />
      </section>
      <section style={sectionStyle}>
        <span style={{textAlign:'left', flexGrow:'1',}}>Pick Up Time</span>
        <ScheduleDate  input = {pickSchedule}company={company} drop={false}/>
      </section>
    </CardContent>
  </Card>
  )
}

function LoginPopup({open, big}){
  const classes = useStyles()
  const[uname, setUser] = React.useState('')
  const[pass, setPass] = React.useState('')
  const[emailError, setEmailError] = React.useState(false)
  const unameHelper = (event)=>{
    setUser(event.target.value )
  }
  const passHelper = (event)=>{
    setPass(event.target.value)
  }
  function login(){
    setEmailError(false)
    console.log('login fired')
    //do some field validation
    firebase.auth().signInWithEmailAndPassword(uname, pass)
    .then(()=>{
      console.log('success')
      //do some login stuff (hide login box and load dahsboard)
      //onSuccess(false)
    })
    .catch(()=>{
      //do some error stuff, invalid email/pass etc
      console.log('some error')
      setEmailError(true)
    }
    )
  }
  function reset(){
    console.log('pword reset')
    //check there is an email
    if (uname!==''){
      firebase.auth().sendPasswordResetEmail(uname)
      .then(()=>{
        alert('Password Reset Email Sent!')
      })
      .catch((error)=>{
        console.log(error)
        setEmailError(true)
      })
    }
  }
  return(
    <Modal 
      open={open}>
    <div className={big?classes.loginStyle:classes.loginMobile} style={{display:'block'}}>
      <Typography variant='h6' align='center' className={big?classes.loginText:classes.loginMobileText}>
        RadonDash Sign-In
      </Typography>
    
      <TextField
          error={emailError&&true}
          id="uname-input"
          label="Email"
          type="email"
          autoComplete="email"
          variant="outlined"
          onChange={unameHelper}
          className={big?classes.loginText:classes.loginMobileText}
          helperText={emailError&&'Invalid Email and/or Password'}
        />
      
      <TextField
          id="password-input"
          error={emailError&&true}
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="outlined"
          onChange={passHelper}
          className={big?classes.loginText:classes.loginMobileText}
        />
      <div>
        <Button className={big?classes.loginText:classes.loginMobileText} style={{width:'50%'}} onClick={()=>{login()}}>Submit</Button>
        <Button className={big?classes.loginText:classes.loginMobileText} style={{width:'50%'}} onClick={()=>{reset()}}>Forgot Password</Button>
      </div>
    </div>
    </Modal>
  );
}

function Welcome({companyInfo, userInfo}){
  const classes = useStyles()
  return(
    <div>
    <Typography variant='h3' align='left'>
      {companyInfo.companyName+' Dashboard'}
    </Typography>
    <Typography variant='h4' align='left'>
      {'Welcome Back '+ userInfo.name}
    </Typography>
    <Divider/>
    </div>
  )
}
function Dashboard({dashInfo, userInfo, users}){
  const classes = useStyles()
  //console.log('dashboard company',dashInfo)
  //console.log('dashboard user',userInfo)
  let deployed = '-'
  let total = '-'
  let totalInv=0
  let totalUsers=0
  let zeroes=0
  dashInfo && (dashInfo.deployed!==undefined) && (deployed=dashInfo.deployed)
  dashInfo && dashInfo.detectorCount && (total=dashInfo.detectorCount)
  //calculate some stats...
  for(const user of users){
    //console.log('userdocloop', user)
    totalUsers++
    totalInv+= user.inventory
    !user.inventory && zeroes++
  }
  //setup obj array to make it easier to change visually later
  let avgInv = totalInv/totalUsers
  let available =  ((total-deployed)>-1)?(total-deployed):'N/A'
  
  let dashObj=[
    {
      title: 'Monitors',
      cat1: 'Total',
      val1: total,
      cat2: 'Deployed',
      val2: deployed,
      cat3: 'Available',
      val3: available
    },
    {
      title: 'Users',
      cat1: 'Average Inventory',
      val1: (avgInv?avgInv:'-'),
      cat2: 'No Inventory',
      val2: zeroes,
      cat3: 'Active',
      val3: 'Stat WIP'
    },
    {
      title: 'Jobs',
      cat1: 'Total',
      val1: (dashInfo&&dashInfo.inspections)?dashInfo.inspections:'-',
      cat2: 'Deployed',
      val2: (dashInfo&&dashInfo.radon)?dashInfo.radon:'-',
      cat3: 'Active',
      val3: 'Stat WIP'
    },
    {
      title: 'Schedule',
      cat1: 'Due Back Today',
      val1: 'WIP',
      cat2: 'Due Back Tomorrow',
      val2: 'WIP',
      cat3: 'Missed Pickups',
      val3: 'WIP'
    },
  ]

  let cards = dashObj&& dashObj.map((cardInfo)=>
    <Card key={cardInfo.title} className={classes.dashCards} >
    <CardContent>
      <Typography variant='h6' gutterBottom>
        {cardInfo.title}
      </Typography>
      <Typography>
        {cardInfo.cat1}
      </Typography>
      <Typography gutterBottom>
        {cardInfo.val1}
      </Typography>
      <Divider />
      <Typography>
        {cardInfo.cat2}
      </Typography>
      <Typography gutterBottom>
        {cardInfo.val2}
      </Typography>
      <Divider />
      <Typography>
        {cardInfo.cat3}
      </Typography>
      <Typography >
        {cardInfo.val3}
      </Typography>
    </CardContent>
  </Card>)
  
  return(
    <Grid container className={classes.cardGrid}>
      {cards}
    </Grid>
  )
  
}

function UserGrid({userDocs}){
  console.log('userdocs in UserGrid', userDocs)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [pageSize, setPageSize]= React.useState(10)
  let columns = [
    {field:'name', headerName: 'Name', sortable:true, flex:1},
    {field:'role', headerName: 'Role', sortable:true, flex:1 , hide: matches? false:true},
    {field:'inv', headerName: 'Inventory', sortable:true, flex:1},
  ]

  let rows = userDocs&&userDocs.map((userDoc)=>{
    return({
      id: userDoc.id,
      name: userDoc.name + ' ' + userDoc.lname,
      role: userDoc.role,
      inv: userDoc.inventory
    })
  })
  /*
  if(!userDocs) {return(null)}
  if (userDocs){
    Object.keys(userDocs).forEach((uid)=>{
      let userDetail = userDocs[uid]
      rows.push({
        id: uid,
        name: userDetail.name + ' ' + userDetail.lname,
        role: userDetail.role,
        inv: userDetail.inventory
      })
    })
   // console.log(rows)
  }
  */

  //verify sort order (default)
  rows.sort((a,b)=>{
    let invA = a.inv
    let invB = b.inv
    if (invA<invB) {
      return 1} else return -1

  })
  return(
    <DataGrid
    rows={rows}
    columns={columns}
    pageSize = {pageSize}
    rowsPerPageOptions={[5,10,20,50, 100]}
    onPageSizeChange={(newSize)=>{setPageSize(newSize)}}
    autoHeight={true}
    pagination
    style={{margin:'10px 20px'}}
    />
  )
}
function MonGrid({monitors, users, schedule}){
  
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [pageSize, setPageSize]= React.useState(10)
  let columns=[
    { field:'id', headerName: 'Number', sortable:true, flex:1 },
    { field:'location', headerName: 'Location', sortable:true, flex:1, renderCell:
  (params)=>{
    return (params.value.replaceAll('<br>', ' '))
  } },
    { field:'calDate', headerName: 'Cal Date', sortable:true, flex:1, type: 'date', hide: matches? false:true},
    { field:'serial', headerName: 'Serial Number', sortable:true, flex:1, hide: matches? false:true },
    { field:'deployed', headerName: 'Deployed', sortable:true, flex:1, hide: matches? false:true },
  ]
  let rows = monitors.map((monDetail)=>{
    //if (!monitors.length || !users.length || schedule.length) return ([])
    //console.log('map called', users, schedule)
    let calDate = monDetail.calDate?monDetail.calDate.toDate():'No Info'
    let location = monDetail.deployed? monDetail.deployloc:monDetail.lasttouchname
    if (!location){
      if(monDetail.deployed){
        //lookup schedule detail
        const search = monDetail.oid
        const index = schedule.find((jobDoc)=> search===jobDoc.id)
        location = (index && schedule)? schedule[index].address : 'No Info'
        
      }
      else{
        //lookup user id
        const search = monDetail.lasttouch
        const index = users.findIndex((userDoc)=>{
          return search === userDoc.id
        })
        location = (index>-1 && users[index])? (users[index].name + ' ' + users[index].lname): ('No Info')
      }
    }
    return({
      id: monDetail.id,
      calDate: calDate,
      serial: monDetail.serialNum,
      deployed: monDetail.deployed?'Yes':'No',
      location: location
    })
  })
  
  return(
    
  <DataGrid
    rows={rows}
    columns={columns}
    pageSize = {pageSize}
    rowsPerPageOptions={[5,10,20,50, 100]}
    onPageSizeChange={(newSize)=>{setPageSize(newSize)}}
    autoHeight={true}
    pagination
    style={{margin:'10px 20px'}}
    />
  )
}
function ScheduleGrid({schedule, users, company}){
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [pageSize, setPageSize]= React.useState(10)

  let columns=[
    {field: 'id', headerName: 'OID', sortable: true, width:100},
    {field: 'address', headerName: 'Address', sortable: false, width:150, renderCell:
    (params)=>(
      <div>
        <Typography variant='body2'>{params.value.address}</Typography>
        <Typography variant='body2'>{params.value.city + ', ' + params.value.state}</Typography>
      </div>
    )},
    {field: 'insp', headerName: 'Inpsector', sortable: true, flex: .5},
    {field: 'inspDate', headerName: 'Inspection Date', sortable: true, type: 'dateTime', flex: 1},
    {field: 'dropUser', headerName: 'Drop-Off User', sortable: true,   flex: 1, renderCell:
    (params)=>(
      <UserSelect input={
        {user: params.value.id,
          userName: params.value.userName,
          done: params.value.done,
          oid: params.id
        }} users={users} company={company} drop={true} />
    )},
    {field: 'dropDate', headerName: 'Drop-Off Date', sortable: true, minWidth:225, type: 'dateTime', flex: 1, renderCell:
    (params)=>(
      <ScheduleDate params={params} input = {{
        date: params.value.date,
        oid: params.id,
        done: params.value.done,
      }} company={company} drop={true}/>
    )},
    {field: 'pickUser', headerName: 'Pick-Up User', sortable: true, flex: 1, renderCell:
    (params)=>(
      <UserSelect input={
        {user: params.value.id,
          userName: params.value.userName,
          done: params.value.done,
          oid: params.id
        }} users={users} company={company} drop={false}/>
    )},
    {field: 'pickDate', headerName: 'Pick-Up Date', sortable: true, minWidth:225, type: 'dateTime', flex:1, renderCell:
    (params)=>(
      <ScheduleDate params={params} input = {{
        date: params.value.date,
        oid: params.id,
        done: params.value.done,
      }} company={company} drop={false}/>
    )},
  ]

  let rows = schedule && schedule.map((jobDetail)=>{

    return({
      id: jobDetail.id,
      address: {
        address:jobDetail.address,
        city: jobDetail.city,
        state: jobDetail.state},
      insp: jobDetail.inspectorName,
      inspDate: jobDetail.inspdate?dateMaker(jobDetail.inspdate.toDate()):'',
      dropDate: {
        date: (jobDetail.dropoffdate && jobDetail.dropoffdate.toDate()),
        done: jobDetail.dropped,
      },
      pickDate: {
        date: (jobDetail.pickupdate && jobDetail.pickupdate.toDate()),
        done: jobDetail.picked,
      },
      dropUser:{
        done: jobDetail.dropped,
        userName: jobDetail.dropoffName,
        id: jobDetail.dropid
      },
      pickUser:{
        done: jobDetail.picked,
        userName: jobDetail.pickupName,
        id: jobDetail.pickid,
      }
      
    })
  })
  return(
    <DataGrid
    rows={rows}
    rowHeight={100}
    columns={columns}
    pageSize = {pageSize}
    rowsPerPageOptions={[5,10,20,50, 100]}
    onPageSizeChange={(newSize)=>{setPageSize(newSize)}}
    autoHeight={true}
    pagination
    style={{margin:'10px 20px'}}
    />
  )
}
function ScheduleDate({input, company, drop}){
  const done = input.done
  
  //console.log('picker', params.value, params.value.toISOString().slice(0,-8))
  const [datetime, setTime] = React.useState(input.date?input.date.toISOString().slice(0,-8):'')
  const handleChange = (event)=>{
    setTime(event.target.value)
    const date = new Date(event.target.value)
    const to = date.getTimezoneOffset()
    date.setMinutes(date.getMinutes()-to)
    let updateObj={}
    if(drop){
      updateObj={
        dropoffdate: date
      }
    } else{
      updateObj={
        pickupdate: date
      }
    }
    db.collection('companies').doc(company).collection('inspections').doc(input.oid).update(updateObj)
  }
  if(done){
    return <Typography variant='body2'>{dateMaker(input.date)}</Typography>
  }else{
    return(
      <TextField
        type='datetime-local'
        value= {datetime}
        onChange={handleChange}
        inputProps={{
          style:{fontSize:'14px'}
        }}
      />
    )
    }
}
function UserSelect({input, users, company, drop, style}){
  //console.log('user select order No', params.id, params.value.userName)
  //set default style
  let applyStyle = style?style:{width:'100%', fontSize:'14px'}
  const dropped = input.done
  const [user, setUser]= React.useState(input.user)
  const handleChange = (event)=>{
    setUser(event.target.value)
    //console.log('handleChange', event.target)
    const user =users.find((doc)=> doc.id===event.target.value)
    let update={}
    if(drop){
      update = {
        dropid: event.target.value,
        dropoffName: user.name +' '+user.lname
      }
    }else{
      update = {
        pickid: event.target.value,
        pickupName: user.name +' '+user.lname
      }
    }

    db.collection('companies').doc(company).collection('inspections')
    .doc(input.oid).update(update)
    
  }
  //console.log('dropped', dropped )
  let select = users && users.map((user)=> <MenuItem key={user.id} value={user.id}>{user.name + ' ' + user.lname}</MenuItem>)
  //console.log('select', select)
  if(dropped){
    return <Typography variant='body2'>{input.userName}</Typography>
  }else{
    return (
    <Select value={user} onChange={handleChange} variant='outlined' style={applyStyle}>
      {select}
    </Select>
    )
  }
}
function MenuBuilder({drawerState, updateState, updateDisplay}){
  function signOut(){
    firebase.auth().signOut()
  }
  const classes = useStyles()
  const menuList = ['Dashboard', 'Inventories', 'Monitors', 'Schedule', 'Worklist']
  const list = menuList.map((item)=>{
    let link = (item==='Worklist')? '/worklist':'/manage/'+item.toLowerCase()
    return(
    <ListItem button component={RouterLink} to= {link} key={item} onClick={()=>{updateDisplay(item); updateState(false);}}>
      <ListItemText primary={item}/>
    </ListItem>
    )
  }
  )
  return(
    <Drawer open={drawerState} onClose={()=>{updateState(false)}} >
    <div className={classes.list} role='presentation'>
    <List>
      {list}
      <ListItem button key='Logout' onClick={()=>{updateState(false); signOut();}}>
        <ListItemText primary='Logout'/>
      </ListItem>
    </List>
    </div>
    </Drawer>
  )
}

function monitorUpdater(monitors, monUpdates){
  //create array and set to monitors state
  monUpdates((prevList)=>{
    let monitorList= [...prevList]
    for(const doc of monitors){
      if(doc.type ==='added'){
        monitorList.push(doc.doc.data())
        let index = monitorList.length-1
        monitorList[index].id=doc.doc.id
      }
      if (doc.type === 'modified'){
        //find user and update
        const index = monitorList.findIndex((monDoc)=> monDoc.id === doc.doc.id)
        monitorList[index]=doc.doc.data()
        monitorList[index].id = doc.doc.id
      }
      if (doc.type === 'removed'){
        //unlikely but slice and remove

      }
    }
    console.log('function monitors', monitorList)
    return monitorList

  })
  
}

function userUpdater(userDocs, updateUserDocs){
  updateUserDocs((prevList)=>{
    let userList=[...prevList]
    for (const user of userDocs){
      if (user.type === 'added'){
        userList.push(user.doc.data())
        let index = userList.length-1
        userList[index].id=user.doc.id
      }
      if (user.type === 'modified'){
        //find user and update
        const index = userList.findIndex((doc)=> doc.id === user.doc.id)
        userList[index]=user.doc.data()
        userList[index].id=user.doc.id
      }
      if (user.type === 'removed'){
        //unlikely but slice and remove

      }
    }
    //console.log('updater userdocs', userList)
    return userList
  })
  
}

async function scheduleUpdater(scheduleDocs, updateSchedule, companyID){
  let jobUpdater = []
  
  for(const scheduleDoc of scheduleDocs){
    if (scheduleDoc.type==='removed'){
      await db.collection('companies').doc(companyID).collection('inspections').doc(scheduleDoc.doc.id)
      .get()
      .then((doc)=>{
        jobUpdater.push(doc.data())
        let index = jobUpdater.length-1
        jobUpdater[index].id = doc.id
        return
      })
      .catch(()=>{
        console.log('removed job issues')
      })
    }
  }

  
  updateSchedule( (prevList)=>{
    console.log('prevList', prevList)
    let scheduleList=[...prevList]
    for(const schedule of scheduleDocs){
      if (schedule.type === 'added'){
        const search = scheduleList.findIndex((doc)=>{
          return doc.id === schedule.doc.id
        })
        if (search===-1){
        scheduleList.push((schedule.doc.data()))
        let index = scheduleList.length-1
        scheduleList[index].id=schedule.doc.id
        } else{
          const index = scheduleList.findIndex((doc)=>{
            return doc.id === schedule.doc.id
          })
          scheduleList[index] = schedule.doc.data()
          scheduleList[index].id = schedule.doc.id
        }
      }
      if (schedule.type === 'modified'){
        //find right object and update
        const index = scheduleList.findIndex((doc)=>{
          return doc.id === schedule.doc.id
        })
        scheduleList[index] = schedule.doc.data()
        scheduleList[index].id = schedule.doc.id
      }
      if(schedule.type === 'removed'){
        //removed means job is no longer radon or was picked up. update as needed
        const index = scheduleList.findIndex((doc)=>{
          return doc.id === schedule.doc.id
        })
        const removedSearch = jobUpdater.findIndex((doc)=>{
          return doc.id === schedule.doc.id
        })

        if(removedSearch>-1){
          if (jobUpdater[removedSearch].picked === true){
            scheduleList[index].picked = true
          }
          if (jobUpdater[removedSearch].radon === false){
            scheduleList.splice(index,1)
          }
        }

      }

    }
    return(scheduleList)
  })
  
  
  
  
  /*
  console.log('checkschedule', currentSchedule)
  let scheduleList= [...currentSchedule]
  console.log('initial copy', scheduleList)
  for(const schedule of scheduleDocs){
    console.log('scheduledoc', schedule.doc.data())
    if (schedule.type === 'added'){
      scheduleList.push((schedule.doc.data()))
      let index = scheduleList.length-1
      scheduleList[index].id=schedule.doc.id
    }
    if (schedule.type === 'modified' || schedule.type==='removed'){
      //find right object and update - removed is most likely a job that closed, do not remove until next refresh
      const index = scheduleList.findIndex((doc)=>{
        return doc.id === schedule.doc.id
      })
      scheduleList[index] = schedule.doc.data()
      scheduleList[index] = schedule.doc.id
    }

  }
  console.log('updated schedule', scheduleList)
  updateSchedule(scheduleList)
  */

}

function dateMaker(date){
  let returnString
  let hours
  let ampm
  let minutes
  let day
  let month
  if (date.getHours()>12){
      hours = date.getHours()-12
      ampm = "PM"
  } else if (date.getHours() == 12) {
      hours = date.getHours()
      ampm = "PM"
  } else {
      hours = date.getHours()
      ampm = "AM"
  }
  
  if (date.getMinutes()<10){
      minutes = "0"+date.getMinutes()
  } else {
      minutes = date.getMinutes()
  }

  if (date.getDate()+1<10){
      day = "0" + date.getDate()
  } else {day = date.getDate()}
  
  if((date.getMonth()+1)<10){
      month = "0" + (date.getMonth()+1)
  } else {month = date.getMonth()+1}
  let year = date.getFullYear().toString().substring(2)

  return returnString = month +`/`+day+`/`+year+` `+hours+`:`+minutes+` `+ampm    

}

export default App;
