import React from 'react'
import { Select, MenuItem } from '@material-ui/core'

const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ]
export function StatePicker({orderState, orderUpdater, defaultState, className, onChange}){
    let select= states.map((stateCode)=> <MenuItem key={stateCode} value={stateCode}>{stateCode}</MenuItem>)
    const [state, setState]=React.useState(defaultState)
    onChange(state)
    console.log('StatePicker!', className)
    //console.log('StatePicker!', defaultState)
    const handleChange = (event)=>{
        setState(event.target.value)
        onChange(event.target.value)
    }
    return(
    <Select variant='outlined' onChange={handleChange} value={state} className={className}>
        {select}
    </Select>
    )
}