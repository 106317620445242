export function dateMaker(date){
    let returnString
    let hours
    let ampm
    let minutes
    let day
    let month
    if (date.getHours()>12){
        hours = date.getHours()-12
        ampm = "PM"
    } else if (date.getHours() == 12) {
        hours = date.getHours()
        ampm = "PM"
    } else {
        hours = date.getHours()
        ampm = "AM"
    }
    
    if (date.getMinutes()<10){
        minutes = "0"+date.getMinutes()
    } else {
        minutes = date.getMinutes()
    }
  
    if (date.getDate()+1<10){
        day = "0" + date.getDate()
    } else {day = date.getDate()}
    
    if((date.getMonth()+1)<10){
        month = "0" + (date.getMonth()+1)
    } else {month = date.getMonth()+1}
    let year = date.getFullYear().toString().substring(2)
  
    return returnString = month +`/`+day+`/`+year+` `+hours+`:`+minutes+` `+ampm    
  
  }